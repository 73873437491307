import React from 'react';

const Quote = (props) => {
    return (
        <div className="quote">
            <div className="quote-bordertop">
                <p className="quote-saying">"{props.quote}"</p>
            </div>
            <div className="quote-borderbottom">
                <p className="quote-author">-{props.author}</p>
            </div>
        </div>
    );
}

export default Quote;