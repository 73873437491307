import React from 'react';
import image5 from '../assets/IMG_9857.jpg';
import {Row, Col, Container} from 'react-bootstrap';
import Sage3 from '../assets/Background/sage3.png';

const Schedule = () => {
    return (
        <div>
        <div className="schedule-panel" style={{ backgroundImage: `url(${image5})` }}>
            <div className="bcg-schedule-img5">
                <img src={Sage3} className="bcg-schedule-img5-src" alt="leaf" />
            </div>
            {/* <div className="panel-photos"></div> */}
            <div className="schedule-panel text-box">
                <div className="schedule-dayof">SCHEDULE</div>
                {/* <div className="schedule-title">Schedule</div> */}
                <hr className="schedule-line-title1" />
                <hr className="schedule-line-title2" />
                <div className="schedule-list">
                    <Container>
                    <Row sm={12} md={12}>
                        <Col className="schedule-title">saturday</Col>
                    </Row>
                        <Row sm={12} md={2}>
                            <Col sm={12} className="time">EVENING</Col>
                            <Col sm={12} className="event">MEHNDI CEREMONY</Col>
                        </Row>
                    <Row sm={12} md={12}>
                        <Col className="schedule-title">sunday</Col>
                    </Row>
                        <Row sm={12} md={2}>
                            <Col sm={12} className="time">MORNING</Col>
                            <Col sm={12} className="event">GETTING READY</Col>
                        </Row>
                        <Row sm={12} md={2}>
                            <Col sm={12} className="time">4:00 PM CDT</Col>
                            <Col sm={12} className="event">CEREMONY AND LIVE-STREAM</Col>
                        </Row>
                        <Row sm={12} md={2}>
                            <Col sm={12} className="time">6:00 PM CDT</Col>
                            <Col sm={12} className="event">RECEPTION</Col>
                        </Row>
                    </Container>
                </div>
            </div> 
        </div>
        {/* <OverlayText 
            title={'Wedding Schedule'} 
            body={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non neque non arcu venenatis sodales sed eu neque. Curabitur scelerisque, nibh vel fermentum tincidunt, est tellus imperdiet ante, eget malesuada massa mauris sed augue. Maecenas molestie tincidunt dolor sit amet fringilla. Aliquam fringilla in velit ut mollis. Sed risus orci, egestas ut nisl et, suscipit vulputate arcu. Vestibulum hendrerit a odio quis vehicula. Praesent non justo quam. Proin dictum porta lacinia. Sed aliquet vestibulum justo, ac lacinia sapien pretium non. Aliquam efficitur dolor tortor, ac ornare velit tempus non. In condimentum ipsum ante, ac vehicula quam cursus quis.'} 
        /> */}
        </div>
    );
}

export default Schedule;