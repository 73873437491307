import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import Lucky from '../assets/Bridesmaids/Lucky.png';
import Diane from '../assets/Bridesmaids/Diane.jpg';
import Hanjing from '../assets/Bridesmaids/Hanjing.jpg';
import Jennifer from '../assets/Bridesmaids/Jennifer.jpg';
import Michelle from '../assets/Bridesmaids/Michelle.jpg';
import Liz from '../assets/Bridesmaids/Liz.jpg';
import Elaine from '../assets/Bridesmaids/Elaine.jpg';
import Beckah from '../assets/Bridesmaids/beckah.png';
import Mady from '../assets/Bridesmaids/mady.jpg';
import Sage7 from '../assets/Background/sage7.png';
import Sage4 from '../assets/Background/sage4.png';
import Quote from './Quote';
import image4 from '../assets/IMG_9875.jpg';
import TeamGroomsmen from './TeamGroomsmen';

const TeamBridesmaids = () => {
    return (
        <div className="bridesmaids">
            <div className="team-bridesmaids-title"><img src={Sage7} className="bcg-bridesmaids-img-src" alt="leaf"/>Team bridesmaids</div>
            <div className="team-bridesmaids-quote">
                <Quote 
                    quote={"Friends are angels that lift us to our feet when our wings have trouble remembering how to fly."}
                    author={"Lorraine K. Mitchell"}    
                />
            </div>
            <div className="bcg-bridesmaids-img2">
                    <img src={Sage4} className="bcg-bridesmaids-img2-src" alt="Flower overlay"/>
                </div>
            <div className="team-bridesmaids-grid">
                <Container>
                    <Row xs={1} sm={2} md={5} className="justify-content-md-center">
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Lucky} className="team-bridesmaids-lucky " alt="Lucky"/>
                            </p> 
                            <p className="team-bridesmaids-name">Lucky</p>
                            <p className="team-bridesmaids-role">Man of honor</p>
                            <p className="team-bridesmaids-quote">Party starter, organizer, and gangster</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Liz} className="team-bridesmaids-liz" alt="Liz"/>
                            </p>
                            <p className="team-bridesmaids-name">Liz</p>
                            <p className="team-bridesmaids-role">bridesmaid</p>
                            <p className="team-bridesmaids-quote">Giving, loveable and Audrey Hepburn vibes</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Diane} className="team-bridesmaids-diane" alt="Diane"/>
                            </p>
                            <p className="team-bridesmaids-name">Diane</p>
                            <p className="team-bridesmaids-role">bridesmaid</p>
                            <p className="team-bridesmaids-quote">S+ Rank Support, beauty-guru, and nap-extraordinare</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Jennifer} className="team-bridesmaids-jennifer" alt="Jennifer"/>
                            </p>
                            <p className="team-bridesmaids-name">Jennifer</p>
                            <p className="team-bridesmaids-role">bridesmaid</p>
                            <p className="team-bridesmaids-quote">Wise, supportive therapist and photographer</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Hanjing} className="team-bridesmaids-hanjing" alt="Hanjing"/>
                            </p> 
                            <p className="team-bridesmaids-name">Hanjing</p>
                            <p className="team-bridesmaids-role">bridesmaid</p>
                            <p className="team-bridesmaids-quote">Pro-climber, genuine, and seductress</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Michelle} className="team-bridesmaids-michelle" alt="Michelle"/>
                            </p>
                            <p className="team-bridesmaids-name">Michelle</p>
                            <p className="team-bridesmaids-role">bridesmaid</p>
                            <p className="team-bridesmaids-quote">Thoughtful, sincere and chip enthusiast</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Elaine} className="team-bridesmaids-elaine" alt="Elaine"/>
                            </p>
                            <p className="team-bridesmaids-name">Elaine</p>
                            <p className="team-bridesmaids-role">bridesmaid</p>
                            <p className="team-bridesmaids-quote">Ninja, techie, and loyal</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Beckah} className="team-bridesmaids-beckah" alt="Beckah"/>
                            </p>
                            <p className="team-bridesmaids-name">Bekah</p>
                            <p className="team-bridesmaids-role">bridesmaid</p>
                            <p className="team-bridesmaids-quote">Bright, bold, and beautiful</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color"></div>
                                <img src={Mady} className="team-bridesmaids-mady" alt="Mady"/>
                            </p>
                            <p className="team-bridesmaids-name">Mady</p>
                            <p className="team-bridesmaids-role">M.C.</p>
                            <p className="team-bridesmaids-quote">Hostess with the Mostess</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="wed-party-panel" style={{ backgroundImage: `url(${image4})` }}>
                <div className="wed-party-photo"></div>
            </div>
            <TeamGroomsmen />
        </div>
    );
}

export default TeamBridesmaids;