import React from 'react';
import last_image from '../assets/IMG_9895.jpg';
import last_image2 from '../assets/IMG_9907.jpg';
import last_image4 from '../assets/IMG_9847.jpg';
import {Row, Col} from 'react-bootstrap';

const Footer = () => {
    return (
        <div className="allfooter">
        <Row md={3} noGutters={true} className="footer-all-images">
            <Col xs={12} sm={4} md={4} noGutters={true}>
                <img src={last_image} className="footer-images" alt="Jess and Rafi holding their dog Indy" />
            </Col>
            <Col xs={12} sm={4} md={4}>
                <img src={last_image2} className="footer-images" alt="Jess and Rafi at gasworks park"/>
            </Col>
            <Col xs={12} sm={4} md={4}>
                <img src={last_image4} className="footer-images" alt=" Jess and Rafi looking at each other" />
            </Col>
        </Row>
        <div className="footer">
            <div className="footer-designed">
                Developed with ♥ by <a href="https://teamlumpia.com/">TeamLumpia LLC</a>
            </div>
        </div>
        </div>
    );
}

export default Footer;