import React from 'react';
import image1 from '../assets/IMG_9928.jpg';
import Sage9 from '../assets/Background/sage9.png';
import OurStory from './OurStory';

const About = () => {
    return (
        <div>
            <div className="about-panel" style={{ backgroundImage: `url(${image1})` }}>
                <div className="bcg-about-img">
                    <img src={Sage9} className="bcg-about-img-src1" alt="flower overlay"/>
                </div>
                <div className="about-panel text-box">
                    About Us
                </div> 
                <div className="panel-photos"></div>
            </div>
            <OurStory />
        </div>
    );
}

export default About;