import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Menu from './screens/Menu';
import NewGuestbook from './screens/NewGuestbook';
import NameEntry from './screens/NameEntry';




const App = () => {
  return (
    <Router>
        <Switch>
          {/* <Route path="/ceremony" exact component={NewGuestbook}><NewGuestbook /></Route> */}
          <Route path="/stream" exact component={NewGuestbook}><NewGuestbook /></Route>
          <Route path="/" exact component={Menu}><Menu /></Route>
        </Switch>
    </Router>
  );
}

export default App;
