import React from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import OurPhoto from "../assets/IMG_9847.jpg";
import Sage5 from "../assets/Background/sage5.png";
import mario from '../assets/mario.png';
import megaman from '../assets/megaman.png';

const OurStory = () => {
    return (
        <div className="panel-ourstory">
            <div className="bcg-ourstory-img3">
                <img src={Sage5} className="bcg-ourstory-img3-src" alt="leaf"/>
            </div>
            <div className="ourstory">
                <div className="ourstory__our">Our</div>
                <div className="ourstory__story">Story</div>
                <hr className="line-story1"/>
            </div>
            <Row className="ourstory__body" md={1} lg={1} xl={3}>
                <Col xs={12} sm={12}>
                <Image src={mario} className="ourstory__body_her" />
                    <div className="ourstory__body_title_her">
                        Her Side
                    </div>
                    <div className="ourstory__body_text">
                        <p>Jess first met Rafi at Old Navy. Her first day on the job, she was tasked with cleaning up the entire clearance section. As she bent down to scoop up a colorful pile of $5.99 t-shirts, she looked to her left and saw Rafi for the first time as he turned the corner. Wearing a purple button up with a tie and leather dress shoes, he approached her waving and smiling to ask how her first day was going. Given how dressed up he was for Old Navy, Jess immediately thought he was a manager. When she later found out he wasn't, she wrote him off as a weirdo and mostly avoided him.</p>
                        <p>Then, one fateful summer, her best friend, Lucky convinced her to take part in a scavenger hunt. Unbeknownst to her, Rafi was also invited to join their team. Throughout that day, they traversed as a team through the city. Overcoming challenge after challenge. With 5 minutes remaining on the countdown clock, the other members of their team were determined to throw in the towel and call it a loss.</p>
                        <p>But not Jess and Rafi! In that instance, they locked eyes, quickly scanned the clue list and decided they'd run sprinting down the street together to squeeze in just one more task (collecting soup cans from a neighboring house). That moment cemented their bond and they've been going on spontaneous adventures together ever since.</p>
                    </div>
                </Col>
                <Col xs={12} sm={12}>
                    <div className="ourstory__body_photo mx-auto">
                        <img src={OurPhoto} alt="Jess and Rafi" className="ourstory__body_photo-img" />
                    </div>
                </Col>
                <Col xs={12} sm={12} className="ourstory__body_hide">
                    <div className="ourstory__body_hide">
                <Image src={megaman} className="ourstory__body_his" />
                </div>
                    <div className="ourstory__body_title_his">
                       His Side
                    </div>
                    <div className="ourstory__body_text">
                        <p>A time not so long ago in the place called Old Navy is where our story begins. Rafi met Jess while she was working hard cleaning up the horrible mess known simply as the “clearance section”. As Rafi knew this was Jess’ first day, he cheerfully asked her how her first day was going. As the days advanced and Jess was furthering her training at Old Navy, she was always so bubbly and enthusiastic no matter what was thrown at her. Her optimism and positive attitude didn’t sit so well with Rafi. He thought to himself, how could someone be enjoying a place like this so much.</p>
                        <p>Lucky being the link that connected the two kept inviting Rafi to events where Jess was at. Eventually, Rafi and Jess were on a team for a scavenger hunt. The obstacles of the day weren’t easy but the team progressed through each one. However, towards the end of the hunt the team was behind and lost all hope. At that darkest hour is when Rafi and Jess joined forces to hunt down soup cans for final points to pull ahead. In the end the team didn’t win but Rafi and Jess did.</p>    
                        <p>After the scavenger hunt Rafi concocted an ingenious plan to impress Jess by texting her every morning, good morning. To no ones surprise, Rafi’s brilliant plan worked. The next step was to invite Jess for the one thing everyone craves in the summer… Ice cream. Hook, line and sinker, Jess took the bait. However, the day Jess arrived for ice cream Rafi’s friends were over building an elaborate Halloween costume. Sadly, Jess did not get her ice cream but she got something even more precious, delicious food from HuHot. Obviously, the next phase was to woo Jess with an adventure to the Kansas State Fair. All was going according to plan, Rafi showed up to pick up Jess in his cool 90s roadster car, which added at least 20 points to his charisma. The fair was an awesome experience, they shared unhealthy yet great food, beat a few rigged games and partook in a few rides where the safety was questionable. And they ended the night talking and stargazing at a park.</p>
                    </div>
                </Col>
            </Row>
        </div>
        
        
    );
}

export default OurStory;