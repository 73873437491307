import React from 'react';
import {Row, Col, Media} from 'react-bootstrap';
import MainPhoto from "../assets/IMG_9875.jpg";
import BcgImage from '../assets/Background/sage1.png';

const NewHome = () => {
    return (
        <div className="main">
            <div className="bcg-home-img">
                <img src={BcgImage} className="bcg-home-img-src" alt="leaf overlay"/>
            </div>
            <Row>
                <Col xs={12} s={8} md={6}>
                    <div className="main__col_left">
                        <Media>
                            <img src={MainPhoto} alt="Jess and Rafi" width="110%" className="main__img" />
                        </Media>
                    </div>
                </Col>
                <Col s={6} md={6} >
                    <div className="main__col_right">
                        <div className="main__text-all">
                            <div className="main__wedding_date">2021 SUNDAY MAY 16 at 4.00PM CDT</div>
                            <div className="main__large_text">The wedding of</div>
                            <div className="main__large_text_jess">JESS</div>
                            <div className="main__ampersand">&amp;</div>
                            <div className="main__large_text_rafi">RAFI</div>
                            <br/>
                        {/* <div className="main__rsvp">
                            <button className="main__rsvp btn btn-outline-light">RSVP</button>
                        </div> */}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default NewHome;