
import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const NewGuestbook = () => {
    return (
        <div className="guestbookmain">
             <h1 className="guestbookmain-welcome-title">Welcome</h1>
             <p>Thank you so much for your patience.  For just the ceremony, please see photographer Justin Casonova's <a href="https://www.youtube.com/watch?v=aBFQvoBPb8I">video</a>.  We hope you enjoy!</p>
             <div className="guestbookmain-welcome-description">
                <YoutubeEmbed embedId="IAbJMlWaeLs" />
             </div>
            
            {/* https://youtu.be/IAbJMlWaeLs */}
        
            <Link to="/" >  
                <Button variant="outline-dark" className="back-button">BACK</Button> 
            </Link>
        </div>
    )
}

export default NewGuestbook;