import React  from "react";
import {Link} from 'react-router-dom';
import Guestbook from "./Guestbook";
import {Carousel, Row, Col, Form, Container, Button} from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/database';
import NewGuestbook from './NewGuestbook';


const config = {
    apiKey: "AIzaSyCjDVYbofD0hiT_0TRDnrqJNeeMuDGFXHo",
    authDomain: "jess-rafi-rsvp.firebaseapp.com",
    databaseURL: "https://jess-rafi-rsvp-default-rtdb.firebaseio.com",
    projectId: "jess-rafi-rsvp",
    storageBucket: "jess-rafi-rsvp.appspot.com",
    messagingSenderId: "283870983435",
    appId: "1:283870983435:web:d361e2f29c9bcadbbade0e"
};

const app = firebase.initializeApp(config);
const database = app.database();

const NewStream = () => {
    const [name, setName] = React.useState("");

    return(
        <div>
        <h1 className="whoseWatching">Who's watching?</h1>
        <Container>    
            <Form>
                <Form.Group as={Row} controlId="formHorizontalName">
                    <Col md={{ span: 4, offset: 4 }}>
                        <Form.Control placeholder="Please enter your name" value={name} onChange={e => {setName(e.currentTarget.value)}}/>
                    </Col>
                </Form.Group>

                <Form.Group className="rsvp-form-submit">
                    <Col>
                        <Button type="submit" variant="outline-dark" bsPrefix="" className="rsvp-form-submit-btn" onClick={
                        e => {
                            e.preventDefault();
                            const id = name.replace(".", "").toLowerCase();
                            database.ref('watchers/' + id).set({
                                name,
                            });
                            window.location.href="/ceremony"
                        }}>Submit</Button>
                    </Col>
                </Form.Group>   
                </Form>
        </Container>
    </div>
    )
}

export default NewStream;