import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { Nav, Navbar, Button} from 'react-bootstrap';
import Home from './Home';
import About from './About';
import Schedule from './Schedule';
import TeamBridesmaids from './TeamBridesmaids';
import RSVP from './RSVP';
import Registry from './Registry';
import Footer from './Footer';

const Menu = (props) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div>
        <div className="menu">
            <Navbar expanded={expanded} expand="lg" className={expanded ? 'navmenu expanded' : 'navmenu'} fixed="top">
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
            <Navbar.Brand className="nav-logo-fullwidth" href="#home">
                <Button className="navmenu button-logo-in" variant="outline-light" size="lg" onClick={() => setExpanded(false)}>
                    <span className="navmenu button-logo-j">J</span>
                    <span className="navmenu button-logo-amp">&#38;</span>
                    <span className="navmenu button-logo-r">R</span>
                </Button>
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto justify-content-right">
                <Nav.Link href="#about">
                    <Button className="navmenu button" variant="outline-light" onClick={() => setExpanded(false)}>About</Button>
                </Nav.Link>
                <Nav.Link href="#schedule">
                    <Button className="navmenu button" variant="outline-light" onClick={() => setExpanded(false)}>Schedule</Button>
                </Nav.Link>
                <Nav.Link href="#bridesmaids">
                    <Button className="navmenu button" variant="outline-light" onClick={() => setExpanded(false)}>Wedding Party</Button>
                </Nav.Link>
                <Navbar.Brand className="nav-logo-collapsed nav-logo-minwidth" href="#home">
                    <Button className="navmenu button-logo-in" variant="outline-light" size="lg" onClick={() => setExpanded(false)}>
                        <span className="navmenu button-logo-j">J</span>
                        <span className="navmenu button-logo-amp">&#38;</span>
                        <span className="navmenu button-logo-r">R</span>
                    </Button>
                </Navbar.Brand>
                <Nav.Link href="#rsvp">
                    <Button className="navmenu button" variant="outline-light" onClick={() => setExpanded(false)}>RSVP</Button>
                </Nav.Link>
                <Nav.Link href="#registry">
                    <Button className="navmenu button" variant="outline-light" onClick={() => setExpanded(false)}>Registry</Button>
                </Nav.Link>
                <Nav.Item className="navigation-links screens">
                    <Nav.Link eventKey="6" as={Link} to="/stream">
                        <Button className="navmenu button" variant="outline-light" onClick={() => setExpanded(false)}>Stream</Button>
                    </Nav.Link>
                </Nav.Item>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </div>

        <div id="home"><Home /></div>
        <div id="about"><About /></div>
        <div id="schedule"><Schedule /></div>
        <div id="bridesmaids"><TeamBridesmaids /></div>
        <div id="rsvp"><RSVP /></div>
        <div id="registry"><Registry /></div>
        <Footer />
        </div>
    );
}

export default Menu;