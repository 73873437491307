import React from 'react';
import Modal from 'react-modal';
import Indy from '../assets/Indy.jpg';
import {Container, Row, Col} from 'react-bootstrap';
import Quote from './Quote';
import TargetImage from '../assets/target.svg';
import CrateImage from '../assets/crate-barrel.svg';
import Paypal from '../assets/paypal.png';
import RafiVenmo from '../assets/rafi-venmo1.png';
import VenmoLogo from '../assets/venmo.svg';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    },
    overlay: {zIndex: 1000}
  };

  Modal.setAppElement(document.getElementById('venmo'));

const Registry = () => {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    function openModal() {
      setIsOpen(true);
    }
  
    // function afterOpenModal() {
    //   // references are now sync'd and can be accessed.
    //   subtitle.style.color = 'blue';
    // }
  
    function closeModal(){
      setIsOpen(false);
    }

    return (
        <div id="test">
            <div className="registry-panel" style={{ backgroundImage: `url(${Indy})` }}>
                <div className="registry-panel text-box">
                    Registry
                </div> 
                <div className="reg-indy-photo"></div>
            </div>
            <div className="overlay-registry__container">
                <div className="overlay-registry__title">
                    <div className="overlay-registry__title-registry">REGISTRY</div>
                    {/* <div className="overlay-registry__title-you">you</div> */}
                </div>
                <Container>
                    <Quote 
                        quote={"I would maintain that thanks are the highest form of thought; \n\
                        and that gratitude is happiness doubled by wonder."}
                        author={"G.K. Chesterton"}    
                    />
                
                    <div className="overlay-registry__body">
                        <p>We will always welcome warm wishes and your attendance. However, if you would 
                            still like to wish us well on our new journey together, please see our registry 
                            at&nbsp; 
                            <a href="https://www.target.com/gift-registry/giftgiver?registryId=06452a411d5b4544afc57456672c90ba&type=WEDDING">
                                Target
                            </a>
                            ,&nbsp;
                            <a href="https://www.crateandbarrel.com/gift-registry/jess-phommachanh-and-rafi-james/r6271896">
                                Crate&#38;Barrel
                            </a>
                            , or feel free to send us a&nbsp;
                            <a href="https://paypal.me/RafiJames">
                                PayPal
                            </a> or <a href="https://venmo.com/Rafi-James">Venmo</a> for our HoneyMoon fund.</p>
                    </div>

                    <Row xs={1} sm={2} md={3} lg={4} className="justify-content-md-center">
                        <Col className="reg-icon-containers">
                            <a href="https://www.target.com/gift-registry/giftgiver?registryId=06452a411d5b4544afc57456672c90ba&type=WEDDING">
                                <img src={TargetImage} className="registry-icons" alt="target icon"/>
                            </a>
                        </Col>
                        <Col className="reg-icon-containers">
                            <a href="https://www.crateandbarrel.com/gift-registry/jess-phommachanh-and-rafi-james/r6271896">
                                <img src={CrateImage} className="registry-icons" alt="Crate and barrel icon"/>
                            </a>
                        </Col>
                        <Col className="reg-icon-containers">
                            <a href="https://paypal.me/RafiJames">
                                <img src={Paypal} className="registry-icons-paypal" alt="paypal icon" />
                            </a>
                        </Col>
                        <Col className="reg-icon-containers" id="venmo">
                            <div style={{cursor: "pointer"}} onClick={openModal}>
                                <img src={VenmoLogo} className="registry-icons" alt="venmo qr code" />
                            </div>
                                <Modal
                                    isOpen={modalIsOpen}
                                    // onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                    ariaHideApp={false}
                                >
                                <img src={RafiVenmo} width="100%" alt="qr venmo code" />
                                <div className="venmo-modal-text">Open the Venmo app on your phone or login via <a href="https://venmo.com/Rafi-James">desktop</a></div>
                                <button className="close-modal-popup" onClick={closeModal}>X</button>
                                 {/* <button className="close-modal-popup" onClick={closeModal}>X</button> */}
                                </Modal> 
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="overlay-registry__title-thank">THANK</div>
            <div className="overlay-registry__title-you">you</div>
        </div>
    );
}
// https://d1v6x81qdeozhc.cloudfront.net/static/assets/j5/logo@2x-11a8ebcb6d56924e3303b945e27a5445.png
export default Registry;