import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import RafiSister from '../assets/Groomsmen/Ruhamah.png';
import JustinL from '../assets/Groomsmen/Justin.PNG';
import Nic from '../assets/Groomsmen/Nic.PNG';
import Glen from '../assets/Groomsmen/Glen.png';
import Jeff from '../assets/Groomsmen/Jeff.PNG';
import Cory from '../assets/Groomsmen/Cory.PNG';
import JustinP from '../assets/Groomsmen/JustinP.PNG';
import John from '../assets/Bridesmaids/Elaine.jpg';
import Sage7 from '../assets/Background/sage7.png';
import Sage8 from '../assets/Background/sage8.png';
import Quote from './Quote';


const TeamGroomsmen = () => {
    return (
        <div className="team-groomsmen">
            <div className="team-groomsmen-title"><img src={Sage7} className="bcg-bridesmaids-img-src" alt="watercolor leaf overlay"/>Team Groomsmen</div>
            <div className="team-groomsmen-quote">
                <Quote 
                    quote={"You see, sometimes friends have to go away, but a part of them stays behind with you."}
                    author={"Ash Ketchum"}    
                />
            </div>
            <div className="bcg-groomsmen-img">
                    <img src={Sage8} className="bcg-groomsmen-img-src" alt="leaf overlay" />
                </div>
            <div className="team-bridesmaids-grid">
                <Container>
                    <Row xs={1} sm={2} md={4} className="justify-content-md-center">
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={RafiSister} className="team-bridesmaids-ruh" alt="Rafi's sister"/>
                            </p> 
                            <p className="team-bridesmaids-name">Ruhamah</p>
                            <p className="team-bridesmaids-role">BEST WOMAN</p>
                            <p className="team-bridesmaids-quote">Free spirit, dependable and eldest</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={JustinL} className="team-bridesmaids-justinl" alt="Justin L." />
                            </p>
                            <p className="team-bridesmaids-name">Justin L.</p>
                            <p className="team-bridesmaids-role">Groomsman</p>
                            <p className="team-bridesmaids-quote">Arch Mage, enlightened and iron-willed</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={Nic} className="team-bridesmaids-nic" alt="Nic" />
                            </p>
                            <p className="team-bridesmaids-name">Nic</p>
                            <p className="team-bridesmaids-role">Groomsman</p>
                            <p className="team-bridesmaids-quote">Guns, Guns and loyal</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={Glen} className="team-bridesmaids-glen" alt="Glen"/>
                            </p>
                            <p className="team-bridesmaids-name">Glen</p>
                            <p className="team-bridesmaids-role">Groomsman</p>
                            <p className="team-bridesmaids-quote">Sesquipedalian, scholar and writer</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={Jeff} className="team-bridesmaids-jeff" alt="Jeff"/>
                            </p> 
                            <p className="team-bridesmaids-name">Jeff</p>
                            <p className="team-bridesmaids-role">Groomsman</p>
                            <p className="team-bridesmaids-quote">Dungeon Master, Destroyer of All Things and teller of tales</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={Cory} className="team-bridesmaids-cory" alt="cory"/>
                            </p>
                            <p className="team-bridesmaids-name">Cory</p>
                            <p className="team-bridesmaids-role">Groomsman</p>
                            <p className="team-bridesmaids-quote">Technical, straight-shooter, fellow Taco Tico connoisseur</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={John} className="team-bridesmaids-john" alt="John"/>
                            </p>
                            <p className="team-bridesmaids-name">John</p>
                            <p className="team-bridesmaids-role">Groomsman</p>
                            <p className="team-bridesmaids-quote">Big brain, happy, and problem-solver</p>
                        </Col>
                        <Col className="team-bridesmaids-col">
                            <p className="team-bridesmaids-cropped-img mx-auto">
                                <div className="team-bridesmaids-img-color-groomsmen"></div>
                                <img src={JustinP} className="team-bridesmaids-justin" alt="Justin P."/>
                            </p>
                            <p className="team-bridesmaids-name">Justin P.</p>
                            <p className="team-bridesmaids-role">Groomsman</p>
                            <p className="team-bridesmaids-quote">Diamond Ranked in LoL, rocket scientist, and lactose intolerant</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default TeamGroomsmen;