import React from 'react';
import {Carousel, Row, Col, Form, Container, Button} from 'react-bootstrap';
import image1 from '../assets/Carousel/jess-and-rafi.png';
import image2 from '../assets/Carousel/jess-looking.png';
import image3 from '../assets/Carousel/rafi-hugging-jess.png';
import Sage3 from '../assets/Background/sage3.png';
import image4 from '../assets/IMG_9864.jpg';
import {Link} from 'react-router-dom';

const RSVP = () => {
    return (
        <div className="rsvp">
            <div className="rsvp-panel" style={{ backgroundImage: `url(${image4})` }}>
                <div className="rsvp-photo"></div>
            </div>
            {/* <div className="bcg-rsvp-img">
                <img src={Sage6} className="bcg-rsvp-img-src" />
            </div> */}
            <div className="bcg-rsvp-img1">
                <img src={Sage3} className="bcg-rsvp-img1-src" alt="leaf fern watercolor" />
            </div>
            <Row noGutters={true}>
                <Col s={7} md={6}>
                <Carousel 
                    fade={true} 
                    touch={false} 
                    interval={6000} 
                    keyboard={false} 
                    control={false} 
                    slide={false} 
                    wrap={true} 
                    pause={false}
                >
                    <Carousel.Item className="rsvp-image">
                        <img
                        className="d-block w-100"
                        src={image1}
                        alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="rsvp-image">
                        <img
                        className="d-block w-100"
                        src={image2}
                        alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="rsvp-image">
                        <img
                        className="d-block w-100"
                        src={image3}
                        alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                </Col>
                <Col className="live-stream">
                <Link to="/stream" >  
                    <Button variant="outline-dark" className="back-button">Live Stream</Button> 
                </Link>
                </Col>
            </Row>
        </div>
    );
}

export default RSVP;